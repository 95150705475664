import { jsonp } from '@ifeng-fe/ui_base';

// apiUrl为webpack注入的全局变量
/* eslint-disable */
let apiBaseUrl = apiUrl;
/* eslint-enable */

// so.ifeng.com
export const getSoFengData = async ({ __type, __k, __page }) => {
    return await jsonp(`${apiBaseUrl}/getSoFengData/${__type}/${__k}/${__page}/getSoFengDataCallback`, {
        jsonpCallback: 'getSoFengDataCallback',
    });
};
