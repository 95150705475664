import React from 'react';
import { NewsStream } from '@ifeng-fe/news-stream';
import '@ifeng-fe/news-stream/lib/NewsStream/style/news-stream-global.css';

import style from './index.css';

import { getSoFengData } from '../../../../services/api';

const tabMap = [
    {
        label: '综合',
        value: 'all',
    },
    {
        label: '图集',
        value: 'slide',
    },
    {
        label: '视频',
        value: 'video',
    },
];

class Content extends React.PureComponent {
    state = {
        data: [],
        count: '0',
        page: 0,
        isEnd: false,
        type: 'all',
        lineBarLeft: 0,
    };

    componentDidMount() {
        const locationSearch = window.location.search;

        if (locationSearch.indexOf('q=') > -1) {
            const params = locationSearch.split('q=')[1].split('&')[0];

            this.refs.input.value = decodeURIComponent(params);
            this.getResult();
        }
        document.addEventListener('keydown', this.keyDown);
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.keyDown);
    }

    search = () => {
        this.setState(
            {
                isLoading: true,
                data: [],
                count: '0',
                page: 0,
                isEnd: false,
            },
            () => {
                this.getResult();
            },
        );
    };

    keyDown = e => {
        if (e.target.name !== 'searchInput') return;

        switch (e.keyCode) {
            case 13:
                this.search();
                break;
        }
    };

    getResult = async () => {
        try {
            // this.setState({
            //     isLoading: true,
            // });
            const { page, data, count, type } = this.state;
            const currentPage = page + 1;

            const value = this.refs.input.value;

            const __isEnd = page !== 0 && page * 20 >= parseInt(count);

            this.setState({
                isEnd: __isEnd,
            });

            if (__isEnd || value === '') {
                this.setState({
                    isLoading: false,
                });

                return { data: { newsstream: [], isEnd: __isEnd } };
            }

            const result = await getSoFengData({ __type: type, __k: value, __page: currentPage });

            const {
                data: { items, total },
            } = result;

            this.setState({
                data: [...data, ...items],
                count: total,
                page: currentPage,
                isEnd: __isEnd,
                isLoading: false,
            });

            return { data: { newsstream: [...items], isEnd: __isEnd } };
        } catch (e) {
            console.log(e);
            alert('系统繁忙，请重试');
        }
    };

    changeTab = (TYPE, INDEX) => {
        this.setState(
            {
                type: TYPE,
                lineBarLeft: 80 * INDEX,
            },
            () => {
                this.search();
            },
        );
    };

    render() {
        const { isEnd, isLoading, type, data, lineBarLeft } = this.state;

        const topSearch = (
            <div className={style.header} key="topsearch">
                <div className="clearfix">
                    <div className={style.logo}>
                        <h1>
                            <img
                                src="//p0.ifengimg.com/ifeng/index/20150921/ifengLogo.png"
                                width="115"
                                alt="凤凰搜索"
                                title="凤凰搜索"
                            />
                        </h1>
                    </div>
                    <div className={style.sotool}>
                        <span className={style.s_input_box}>
                            <input
                                name="searchInput"
                                id="searchInput"
                                ref={'input'}
                                className={style.s_input}
                                type="text"
                            />
                        </span>

                        <input className={style.s_button} hidefocus="true" onClick={this.search} />
                    </div>
                </div>
            </div>
        );

        const tabDom = tabMap.map((item, i) => {
            const isCurrent = type === item.value;

            const config = isCurrent ? {} : { onClick: () => this.changeTab(item.value, i) };

            return (
                <span key={item.value} className={isCurrent ? style.current : null} {...config}>
                    {item.label}
                </span>
            );
        });

        const list = (
            <div className={style.content} key="content">
                {/* 每页请求的数据经过过滤了，无法计算未来数据的总条数 */}
                {/* <p style={{ borderBottom: '1px solid #e5e5e5' }}> */}
                {/* 共找到<b>{count}</b>条 */}
                {/* 找到如下结果: */}
                {/* </p> */}

                <div className={style.tabBox}>
                    <div className={style.tabBoxInner}>
                        {tabDom}

                        <div
                            className={style.lineBar}
                            style={{
                                transform: `translate3d(${lineBarLeft}px, 0px, 0px)`,
                            }}
                        />
                    </div>
                </div>

                {isLoading ? (
                    <div style={{ textAlign: 'center', padding: 20 }}>正在努力加载...</div>
                ) : (
                    <NewsStream
                        handleLoadingDataFunc={this.getResult}
                        isScrollLoadingData={true}
                        isCount={false}
                        content={data}
                        isEnd={isEnd}
                    />
                )}
            </div>
        );

        return [topSearch, list];
    }
}

export default Content;
